import axios from 'axios';
import { useEffect, useState } from 'react';
import { useOpenInWindow } from 'use-open-window';
import TelematicsPolicy from '../../model/TelematicsPolicy';
import { getProgramConfig, getStatusLabel } from '../codes';

export interface UbiWindowLinkProps {
  id: string;
}

const UbiWindowLink = ({ id }: UbiWindowLinkProps) => {
  const [status, setStatus] = useState<any[]>([]);
  const [closed, setClosed] = useState(true);

  const url = `/${encodeURIComponent(id)}`;

  const [handleWindowOpen, newWindowHandle] = useOpenInWindow(url, {
    name: id,
    centered: true,
    focus: true,
    specs: {
      width: 800,
      height: 768,
    },
  });

  useEffect(() => {
    if (newWindowHandle !== undefined) {
      const t = setInterval(() => {
        setClosed(newWindowHandle?.closed ?? false);
      }, 1000);

      return () => {
        clearInterval(t);
      };
    }
  }, [newWindowHandle]);

  useEffect(() => {
    console.info(id, { closed });

    if (closed === true) {
      axios
        .get<TelematicsPolicy>(`/CFA/getUbiData.do?id=${id}`)
        .then(({ data }) => {
          console.info('starting data', id, data);
          return data;
        })
        .then(({ telematicsProducts }) => [
          ...telematicsProducts
            .filter(({ productCode }) => getProgramConfig(productCode).OperatorProduct)
            .map(({ telematicsOperators, productCode }) =>
              telematicsOperators?.map(({ firstName, telematicsStatus, renewalTelematicsStatus }) => ({
                [firstName]: `${getStatusLabel(productCode, telematicsStatus, false)} (${telematicsStatus}), renewal: [${getStatusLabel(
                  productCode,
                  renewalTelematicsStatus ?? '',
                  false,
                )}]`,
              })),
            )
            .flat(),
          ...telematicsProducts
            .filter(({ productCode }) => getProgramConfig(productCode).VehicleProduct)
            .map(({ telematicsVehicles, productCode }) =>
              telematicsVehicles?.map(({ year, make, model, telematicsStatus }) => ({
                [`${year} ${make} ${model}`]: getStatusLabel(productCode, telematicsStatus, false),
              })),
            )
            .flat(),
        ])
        .then((ops) => setStatus(ops));
    }
  }, [closed, id]);

  return (
    <>
      <a href={url} onClick={handleWindowOpen}>
        {id}
      </a>
      <ul>
        {status &&
          status.map((e, i) => (
            <li key={i}>
              <code>{JSON.stringify(e)}</code>
            </li>
          ))}
      </ul>
    </>
  );
};

export default UbiWindowLink;
