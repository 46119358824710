import parse from 'html-react-parser';

export interface ProgramDescriptionProps {
  programName?: string;
  programInfo: string;
}

const ProgramDescription = ({ programName, programInfo }: ProgramDescriptionProps) => {
  return (
    <>
      {programName && (
        <h5>
          <b>{programName}</b>
        </h5>
      )}

      <div role="contentinfo" className="px-4">
        {programInfo && parse(programInfo)}
        {!programInfo && 'Description Not Found'}
      </div>
    </>
  );
};

export default ProgramDescription;
