import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function StyledFormCheck({ label, isChecked, disable, onChange }) {
  const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

  return (
    <ConditionalWrapper
      condition={disable && !isChecked}
      wrapper={(children) => (
        <OverlayTrigger overlay={<Tooltip>Please complete this transaction before re-enrolling.</Tooltip>} placement="left">
          {children}
        </OverlayTrigger>
      )}
    >
      <Form.Check
        className="my-2"
        custom="true"
        label={parse(label)}
        checked={isChecked}
        disabled={disable}
        feedback="You must agree before submitting."
        onChange={onChange}
      />
    </ConditionalWrapper>
  );
}

export default StyledFormCheck;

StyledFormCheck.defaultProps = {
  label: '',
  isChecked: false,
  disable: false,
  onChange: () => {},
};

StyledFormCheck.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  disable: PropTypes.bool,
  onChange: PropTypes.func,
};
