import axios from 'axios';
import TelematicsPolicy from '../../model/TelematicsPolicy';

export const getProductsAvailable = async (url: string) => {
  const { data } = await axios.get<TelematicsPolicy>(url, { withCredentials: true });
  return data;
};

export const updateProductsAvailable = async (url: string, body: TelematicsPolicy) => {
  const { data } = await axios.post<TelematicsPolicy>(url, body, { withCredentials: true });
  return data;
};

const CFService = {
  getProductsAvailable,
  updateProductsAvailable,
};

export default CFService;
