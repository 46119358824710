import {
  Button,
  Form,
  GridCol,
  GridRow,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  SelectOption,
} from '@lmig/lmds-react';
import { useCallback, useState } from 'react';
import CfaUtils from '../utils/CfaUtils';

export interface SalesRepConfirmationProps {
  onConsent: (consent: boolean) => void;
  hasOperatorParticipation?: boolean;
}

const SalesRepConfirmation = ({ onConsent, hasOperatorParticipation }: SalesRepConfirmationProps) => {
  const [consent, setConsent] = useState<string>(CfaUtils.isLocal() ? 'Yes' : 'No');
  const [isOpen, setIsOpen] = useState(true);

  const onSubmit = useCallback(() => {
    setIsOpen(false);
    onConsent(consent === 'Yes' || consent === 'NA');
  }, [consent, onConsent]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    onConsent(false);
  }, [onConsent]);

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} isVerticallyCentered={true} size="large">
      <ModalHeader>
        <Heading tag="span" type="h3-light">
          Please read the following to the customer:
        </Heading>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          <GridRow>
            <GridCol base={12}>
              "RightTrack is a safe driving program that reviews your driving habits and rewards safe driving. By participating in the
              program we will immediately include up to 15% savings on your policy today.{' '}<br /><br />
              <i>
                <b>In order to keep your 15% participation discount</b>
              </i>, all household drivers must actively participate in RightTrack using their mobile phone*. Partial household participation will result in a 10% participation discount. To maintain your participation discount, simply stay enrolled in the program. Safe drivers will be rewarded with a discount while others could see a rate increase."
              <br /><br />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol base={12}>
              <i>Question requiring customer response:</i><br /> Do you all have smartphones and are you all willing to participate? <br /><br />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol base={6}></ GridCol>
            <GridCol base={6}>
              <div className="right-dropdown">
                <Select id="consentSelect" isRequired labelVisual="Consent" onChange={(e) => setConsent(e.currentTarget.value)} value={consent}>
                  <SelectOption value="No">No</SelectOption>
                  <SelectOption value="Yes">Yes</SelectOption>
                  {hasOperatorParticipation && <SelectOption value="NA">N/A - This is a Service Call</SelectOption>}
                </Select>
              </div>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol base={12}>
              <br /><i>*All participants must download the LM Mobile App, complete registration, and keep the
                app installed on their phones with required permissions enabled while driving.</i>
            </GridCol>
          </GridRow>
        </Form>
      </ModalBody>
      <ModalFooter>
        <GridRow gutterSize="fixed-16" gutters>
          <GridCol md={6}>
            <Button dynamicWidth onClick={handleCancel}>
            Cancel
            </Button>
          </GridCol>
          <GridCol md={6}>
            <Button dynamicWidth variant="primary" onClick={onSubmit}>
              Apply
            </Button>
          </GridCol>
        </GridRow>
      </ModalFooter>
    </Modal>
  );
};

export default SalesRepConfirmation;
