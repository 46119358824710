import { Content, FloatingHeader, GridCol, GridRow, HeaderAppName, Toggle } from '@lmig/lmds-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import TelematicsProduct from '../../model/TelematicsProduct';
import { RIGHTTRACK_CONTINUOUS_PRICING } from '../codes';
import { isDefault, isParticipating } from '../product';
import { getProductsAvailable, updateProductsAvailable } from '../services/CFService';
import { getHealth } from '../services/UBIService';
import CfaUtil from '../utils/CfaUtils';
import ErrorAlert from './ErrorAlert';
import ProgramParticipation from './ProgramParticipation';
import StyledFormCheck from './StyledFormCheck';
import LegacyProgramParticipation from './UBI/LegacyProgramParticipation';

export interface UbiProps {
  transactionId: string | number;
  cfaUrl: string;
}

const UBI = ({ transactionId, cfaUrl }: UbiProps) => {
  const getUrl = useMemo(() => CfaUtil.getFetchUbiDataUrl(cfaUrl, transactionId), [cfaUrl, transactionId]);
  const putUrl = useMemo(() => CfaUtil.getUpdateUbiDataUrl(cfaUrl, transactionId), [cfaUrl, transactionId]);

  const [build, setBuild] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [error, setError] = useState();
  const [showAdmin, setShowAdmin] = useState(false);

  // CFA values
  const [products, setProducts] = useState<TelematicsProduct[]>([]);
  const [policyEffectiveDate, setPolicyEffectiveDate] = useState<string>('');
  const [transactionEffectiveDate, setTransactionEffectiveDate] = useState<string>('');

  const [isRTAdmin, setIsRTAdmin] = useState(false);
  const [bindValidationBypass, setBindValidationBypass] = useState(false);
  const [showValidationOverride, setShowValidationOverride] = useState(false);

  // RTM form values
  const [isLegacyUbi, setIsLegacyUbi] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { SHOW_VALIDATION_OVERRIDE, build, version } = await getHealth();
        setShowValidationOverride(SHOW_VALIDATION_OVERRIDE);
        setBuild(build ?? 'local');
        setVersion(version ?? '0.0.0');
      } catch (ex: any) {
        setError(ex.message);
      }
    })();
  }, []);

  useEffect(() => {
    console.info('Get data from CFA', { transactionId }, 'via', { getUrl });

    getProductsAvailable(getUrl)
      .then((data) => {
        // get all products from cfa
        const { telematicsProducts, policyEffectiveDate, transactionEffectiveDate, rightTrackAdmin, bindValidationBypass } = data;
        console.info('telematicsProducts', telematicsProducts);

        const participatingProducts = telematicsProducts.filter(isParticipating);
        console.info('participatingProducts', participatingProducts);

        const defaultProducts = telematicsProducts.filter(isDefault);
        console.info('defaultProducts', defaultProducts);

        const targetProduct = [...participatingProducts, ...defaultProducts].at(0);
        console.info('targetProduct', targetProduct);

        setProducts(telematicsProducts);
        setPolicyEffectiveDate(policyEffectiveDate ?? '');
        setTransactionEffectiveDate(transactionEffectiveDate ?? '');
        setIsRTAdmin(rightTrackAdmin || false);
        setBindValidationBypass(bindValidationBypass || false);
        setIsLegacyUbi(targetProduct?.productCode !== RIGHTTRACK_CONTINUOUS_PRICING);
        setError(undefined);
      })
      .catch((ex: any) => {
        console.error(ex);
        setError(ex);
      });
  }, [getUrl, transactionId]);

  const handlePost = useCallback(
    async (body: any) => {
      console.log('handlePost', { body });
      try {
        const data = await updateProductsAvailable(putUrl, body);

        console.info('POST', { body }, { data });
        setError(undefined);
      } catch (ex: any) {
        setError(ex);
      }
    },
    [putUrl],
  );

  return (
    <>
      {transactionId && products.length === 0 && !error && <Alert variant="success">Loading...</Alert>}

      {error && <ErrorAlert error={error} transactionId={transactionId} />}

      {transactionId && products.length > 0 && (
        <>
          {isLegacyUbi && (
            <LegacyProgramParticipation
              telematicsProducts={products}
              onPost={handlePost}
              isRTAdmin={isRTAdmin}
              bindValidationBypass={bindValidationBypass}
              version={version}
              build={build}
            />
          )}
          {!isLegacyUbi && (
            <Content fullWidth>
              <FloatingHeader brand="liberty" isFullBackground isFullWidth className="mt-0">
                <HeaderAppName>Usage Based Insurance Eligibility and Enrollment</HeaderAppName>
              </FloatingHeader>
              <ProgramParticipation
                telematicsProducts={products}
                policyEffectiveDate={policyEffectiveDate}
                transactionEffectiveDate={transactionEffectiveDate}
                onPost={handlePost}
                isRTAdmin={isRTAdmin}
                bindValidationBypass={bindValidationBypass}
                setError={setError}
                version={version}
                build={build}
              />
            </Content>
          )}
        </>
      )}

      {showValidationOverride && (
        <Card className="mt-2">
          <Card.Header>
            <Toggle id="ubi-admin" labelVisual="Admin Console" onChange={() => setShowAdmin(!showAdmin)} checked={showAdmin} />
          </Card.Header>
          {showAdmin && (
            <Card.Body>
              <GridRow gutters>
                <GridCol base={6}>Validation Override</GridCol>
                <GridCol base={6}>
                  <StyledFormCheck
                    isChecked={bindValidationBypass}
                    label="Ignore check for RightTrack participation on other policies"
                    onChange={(e) => {
                      setBindValidationBypass(e.target.checked);
                    }}
                  />
                </GridCol>
              </GridRow>
            </Card.Body>
          )}
        </Card>
      )}
    </>
  );
};

export default UBI;
