import PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { BY_MILE, getParticipatingCode, getProgramLabel } from '../../codes';
import MileageSelect from './MileageSelect';
import ProgramStatusSelect from './ProgramStatusSelect';

const displayByMileMileage = (options, telematicsStatus, productCode) =>
  options && Object.keys(options).length > 0 && BY_MILE === productCode && telematicsStatus === getParticipatingCode(productCode, true);

const VehicleProgramSelection = ({ telematicsVehicles, onChange, products, legacyProduct }) => {
  const handleChange = (vehicleId, status) => {
    onChange({
      target: {
        value: telematicsVehicles.map((vehicle) =>
          vehicle.vehicleId === vehicleId
            ? {
                ...vehicle,
                telematicsStatus: status,
              }
            : vehicle,
        ),
      },
    });
  };

  const handleChangeMileage = (vehicleId, mileage) => {
    onChange({
      target: {
        value: telematicsVehicles.map((vehicle) =>
          vehicle.vehicleId === vehicleId
            ? {
                ...vehicle,
                annualMileageWhenAddedOverride: mileage,
              }
            : vehicle,
        ),
      },
    });
  };

  return (
    <>
      <Row className="form-group blue-background p-2">
        <Col>Vehicles</Col>
        {legacyProduct && (
          <Col sm={3}>
            Program
          </Col>
        )}
        <Col>Status</Col>
      </Row>

      {telematicsVehicles.map(
        ({
          make,
          model,
          year,
          vehicleId,
          telematicsStatus,
          telematicsStatusCodes,
          annualMileageWhenAddedOverride,
          annualMileageWhenAdded,
          options,
          productCode,
        }) => [
          <Form.Group as={Row} controlId={`vehicle-${vehicleId}`} key={vehicleId} className="mb-1">
            <Col>
              <Form.Label>
                {year}
                &nbsp;
                {make}
                &nbsp;
                {model}
              </Form.Label>
            </Col>
            {legacyProduct && (
              <Col sm={3}>
                <Form.Control as="select" custom="true" onChange={() => {}} value={productCode} disabled>
                  {products.map((product) => (
                    <option key={product.productCode} value={product.productCode}>
                      {getProgramLabel(product.productCode)}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            <Col>
              <ProgramStatusSelect
                productCode={productCode}
                programStatusCodes={telematicsStatusCodes || []}
                value={telematicsStatus}
                onChange={(e) => handleChange(vehicleId, e.target.value)}
                addBlank={!telematicsStatus || telematicsStatus.length === 0}
              />
            </Col>
          </Form.Group>,
          displayByMileMileage(options, telematicsStatus, productCode) && (
            <Form.Group as={Row} controlId={`vehicle-${vehicleId}-mileage`} key={`${vehicleId}-mileage`}>
              <Col>
                <Form.Label>ByMile Mileage</Form.Label>
              </Col>
              <Col>
                <MileageSelect
                  mileageOptions={options}
                  value={annualMileageWhenAddedOverride || annualMileageWhenAdded}
                  onChange={(e) => handleChangeMileage(vehicleId, e.target.value)}
                  addBlank={false}
                />
              </Col>
            </Form.Group>
          ),
          <Row key={`${vehicleId}-space`} className="mb-0">
            &nbsp;
          </Row>,
        ],
      )}
    </>
  );
};

export default VehicleProgramSelection;
VehicleProgramSelection.defaultProps = {
  onChange: () => {},
  products: [],
  legacyProduct: false,
};

VehicleProgramSelection.propTypes = {
  telematicsVehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  legacyProduct: PropTypes.bool,
};
