import { datadogRum } from '@datadog/browser-rum';

datadogRum.setGlobalContextProperty('troux_uuid', '67A13F4C-9C5F-4747-B3F9-975297D334A8');

const getEnv = () => {
  const host = window.location.hostname;
  let env;
  if (host.includes('localhost') || host.includes('12.0.0.1')) {
    env = 'local';
  } else if (host.includes('ubi-screen-development')) {
    env = 'development';
  } else if (host.includes('ubi-screen-test')) {
    env = 'test';
  } else if (host.includes('ubi-screen')) {
    env = 'production';
  } else {
    env = undefined;
  }
  return env;
};

const init = () => {
  datadogRum.init({
    applicationId: 'bbda5550-fc6d-45df-bffd-4232130db4d2',
    clientToken: 'pub62818c7c7dba66e90395baa7118ddedb',
    site: 'datadoghq.com',
    service: 'ubi-react',
    sampleRate: 100,
    sessionReplaySampleRate: 0,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
    env: getEnv(),
  });
};

export default init;
