import { isEmpty } from 'lodash';
import TelematicsOperator from '../model/TelematicsOperator';
import TelematicsProduct from '../model/TelematicsProduct';
import TelematicsVehicle from '../model/TelematicsVehicle';
import { getParticipatingCode, getStatusCodeType, isCodeImmutable, isDefaultProduct, isFinalDiscountOrSurchargeCode } from './codes';

export const setProductOperatorStatus = (operator: TelematicsOperator, status: string) => {
  console.info('setProductOperatorStatus', { operator, status });

  return { ...operator, telematicsStatus: status, previouseTelematicsStatus: operator.telematicsStatus };
};

export const setProductStatus = (product: TelematicsProduct, status: string, operatorId: string) => {
  console.info('setProductStatus', { product, status, operatorId });

  return {
    ...product,
    telematicsOperators: product.telematicsOperators.map((operator) =>
      operator.customerId === operatorId && !isCodeImmutable(product.productCode, operator.telematicsStatus)
        ? setProductOperatorStatus(operator, status)
        : operator,
    ),
  };
};

export const getOperatorIds = (telematicsProducts: TelematicsProduct[], productCode: string) => {
  console.info('getOperatorIds', { telematicsProducts, productCode });

  return (telematicsProducts || [])
    .filter((p) => p.productCode === productCode)
    .map((product) => product.telematicsOperators.reduce<string[]>((p, c) => [...p, c.customerId], []))
    .flat();
};

export const isDefault = (product: TelematicsProduct) => {
  let result = false;

  if (product) {
    const { productCode } = product;
    result = isDefaultProduct(productCode);
  }

  console.info('isDefault', { product }, result);

  return result;
};

// determines if a given product object is configured to be participating based on status codes. see /lib/codes.js
export const isParticipating = (product: TelematicsProduct) => {
  let participating = false;

  if (product) {
    const { telematicsOperators, telematicsVehicles, productCode } = product;
    const code = getParticipatingCode(productCode, true);

    if (!isEmpty(telematicsOperators)) {
      participating = telematicsOperators
        .map(({ telematicsStatus }) => telematicsStatus === code || isFinalDiscountOrSurchargeCode(productCode, telematicsStatus))
        .reduce((p, c) => p || c, false);
    }

    if (!isEmpty(telematicsVehicles)) {
      participating = telematicsVehicles
        .map(
          ({ telematicsStatus }) =>
            (Array.isArray(code) && code.includes(telematicsStatus)) ||
            telematicsStatus === code ||
            isFinalDiscountOrSurchargeCode(productCode, telematicsStatus),
        )
        .reduce((p, c) => p || c, false);
    }
  }

  console.info('isParticipating', { product }, participating);

  return participating;
};

export const areAllParticipating = (product: TelematicsProduct) => {
  let participating = false;

  if (product) {
    const { telematicsOperators, telematicsVehicles, productCode } = product;
    const code = getParticipatingCode(productCode, true);

    if (!isEmpty(telematicsOperators)) {
      participating = telematicsOperators
        .every(({ telematicsStatus }) => telematicsStatus === code || isFinalDiscountOrSurchargeCode(productCode, telematicsStatus));
    }

    if (!isEmpty(telematicsVehicles)) {
      participating = telematicsVehicles
        .every(
          ({ telematicsStatus }) =>
            (Array.isArray(code) && code.includes(telematicsStatus)) ||
            telematicsStatus === code ||
            isFinalDiscountOrSurchargeCode(productCode, telematicsStatus),
        );
    }
  }

  console.info('isParticipating', { product }, participating);

  return participating;
};

export const getCodeTypes = (program: string, list: (TelematicsOperator | TelematicsVehicle)[]) => {
  console.info('getCodeTypes', { program, list });
  return Array.isArray(list) ? list.map((item) => getStatusCodeType(program, item.telematicsStatus)) : [];
};

export const findProduct = (products: TelematicsProduct[], productCode: string) => {
  console.info('findProduct', { products, productCode });
  const product = products.filter((product) => product.productCode === productCode)[0];
  return product;
};

export const setOperatorStatus = (operators: TelematicsOperator[], status: string, operatorId: string) => {
  console.info('setOperatorStatus', { status, operatorId });
  return operators.map((operator) => (operator.customerId === operatorId ? { ...operator, telematicsStatus: status } : operator));
};
