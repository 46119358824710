import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';

function MileageSelect({ mileageOptions, value, onChange, addBlank, errorText, ...rest }) {
  return (
    <>
      <Form.Control as="select" custom="true" value={value || ''} onChange={onChange || (() => {})} {...rest}>
        {addBlank && <option />}
        {mileageOptions &&
          mileageOptions.map((eachOption) => (
            <option key={eachOption.key} value={eachOption.key}>
              {eachOption.value}
            </option>
          ))}
      </Form.Control>
      {errorText && <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>}
    </>
  );
}

export default MileageSelect;
MileageSelect.defaultProps = {
  onChange: () => {},
  value: '',
  addBlank: false,
  errorText: undefined,
};

MileageSelect.propTypes = {
  mileageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  addBlank: PropTypes.bool,
  errorText: PropTypes.string,
};
