import { uniqWith } from 'lodash';
import { useMemo } from 'react';
import Feedback from 'react-bootstrap/Feedback';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import { getStatusLabel, isIneligibleCode } from '../../codes';

export interface ProgramStatusSelectProps extends FormControlProps {
  productCode: string;
  programStatusCodes: (string | number)[];
  value?: string;
  addBlank?: boolean;
  errorText?: string;
  isRTAdmin?: boolean;
}

function ProgramStatusSelect({
  productCode,
  programStatusCodes,
  isRTAdmin,
  value,
  onChange,
  addBlank,
  errorText,
  ...props
}: ProgramStatusSelectProps) {
  const isIneligible = useMemo(() => isIneligibleCode(productCode, value ?? ''), [productCode, value]);

  return (
    <>
      <FormControl as="select" custom="true" value={value} onChange={onChange} {...props}>
        {addBlank && <option />}
        {uniqWith([value ?? '', ...programStatusCodes], (a, b) => `${a}` === `${b}`)
          .filter((code) => !isIneligible || (isIneligible && value === code))
          .map((code) => ({ code: code.toString(), label: [getStatusLabel(productCode, code, isRTAdmin ?? false)].flat() }))
          .filter(({ code, label }) => code && label.length > 0)
          .map(({ code, label }, key) => (
            <option key={key} value={code}>
              {label}
            </option>
          ))}
      </FormControl>
      {errorText && (
        <Feedback type="invalid" role="error">
          {errorText}
        </Feedback>
      )}
    </>
  );
}

export default ProgramStatusSelect;
