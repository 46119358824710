import axios from 'axios';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Alert, Badge, Button, Card, Col, Container, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import UbiWindowLink from './UbiWindowLink';

const PremiumSummary = () => {
  const [ids, setIds] = useState<string[]>([]);
  const [resetData, setResetData] = useState(false);
  const [error, setError] = useState();
  const [build, setBuild] = useState();
  const [version, setVersion] = useState();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('/health');
        setBuild(data.build);
        setVersion(data.version);
      } catch (ex: any) {
        setError(ex.message);
      }
    })();
  }, []);

  useEffect(() => {
    if (isArray(ids) && isEmpty(ids)) {
      (async () => {
        try {
          const { data } = await axios.get('/CFA');

          setIds(data.ids);
          setError(undefined);
        } catch (ex: any) {
          setError(ex.message);
        }
      })();
    }
  }, [ids]);

  useEffect(() => {
    if (resetData) {
      (async () => {
        try {
          await axios.get('/CFA/reset');
          setIds([]);
          setError(undefined);
        } catch (ex: any) {
          setError(ex.message);
        } finally {
          setResetData(false);
        }
      })();
    }
  }, [resetData]);

  return (
    <Container className="p-2">
      <Card>
        <Card.Header>
          <Stack gap={2} direction="horizontal">
            Premium Summary Screen
            <Badge bg="info">
              {version} ({build})
            </Badge>
          </Stack>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <h3>Test Policies</h3>
              {ids.map((id, i) => (
                <li key={i}>
                  <UbiWindowLink id={id} />
                </li>
              ))}
            </Col>
            <Col xs="auto">
              <OverlayTrigger overlay={<Tooltip>Resets the test data back to is orginal state.</Tooltip>}>
                <Button onClick={() => setResetData(true)} disabled={resetData}>
                  Reset Data
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer />
      </Card>
      {error && (
        <Alert variant="danger" className="mt-2">
          <pre>{JSON.stringify({ error }, null, 2)}</pre>
        </Alert>
      )}
    </Container>
  );
};

export default PremiumSummary;
