import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function StyledFormSelect({ label, isChecked, disable, myChange }) {
  const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

  return (
    <ConditionalWrapper
      condition={disable && !isChecked}
      wrapper={(children) => (
        <OverlayTrigger overlay={<Tooltip>Please complete this transaction before re-enrolling.</Tooltip>} placement="left">
          {children}
        </OverlayTrigger>
      )}
    >
      <div>
        <Form.Control
          className="my-2"
          as="select"
          custom="true"
          label={parse(label)}
          disabled={disable}
          feedback="You must agree before submitting."
          onChange={myChange}
          value={isChecked}
        >
          <option value="false">No</option>
          <option value="true">Yes</option>
        </Form.Control>
      </div>
    </ConditionalWrapper>
  );
}

export default StyledFormSelect;

StyledFormSelect.defaultProps = {
  label: '',
  isChecked: false,
  disable: false,
  myChange: () => {},
};

StyledFormSelect.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  disable: PropTypes.bool,
  myChange: PropTypes.func,
};
