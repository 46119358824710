import { AxiosError } from 'axios';
import { isError } from 'lodash';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import JsonView from 'react18-json-view';
import CfaUtils from '../utils/CfaUtils';

export interface ErrorAlertProps {
  error: String | Error | AxiosError;
  transactionId: String | number;
}

const isAxiosError = (error: any): error is AxiosError => error.name === 'AxiosError';

const ErrorAlert = ({ error, transactionId }: ErrorAlertProps) => {
  return (
    <Alert variant="danger">
      <p>
        <br />
        {transactionId && (
          <>
            There was an error loading transaction <b>{transactionId}</b>.
          </>
        )}
        {!transactionId && !CfaUtils.isLocal() && <>A transaction id is required.</>}
        {!transactionId && CfaUtils.isLocal() && (
          <>
            Try <a href="/PremiumSummary">PremiumSummary</a>
          </>
        )}
      </p>
      {DEBUG === 'true' && (
        <>
          {isAxiosError(error) && (
            <p>
              <b>Message:</b> {error.message}
              <br />
              <b>Url:</b> {error.config?.url}
            </p>
          )}

          {isError(error) && <JsonView collapsed src={error} />}
        </>
      )}
    </Alert>
  );
};

ErrorAlert.defaultProps = {
  error: 'unknown error',
};

ErrorAlert.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error), PropTypes.instanceOf(AxiosError)]),
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ErrorAlert;
