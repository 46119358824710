import { Button, Content, Form, GridCol, GridRow, Heading, List, ListItem } from '@lmig/lmds-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import TelematicsOperator from '../../model/TelematicsOperator';
import TelematicsProduct from '../../model/TelematicsProduct';
import { RIGHTTRACK_CONTINUOUS_PRICING, RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES, getContinuousPricingDropdownCodes } from '../codes';
import {areAllParticipating, isParticipating} from '../product';
import DebugConsole from './DebugConsole';
import SalesRepConfirmation from './SalesRepConfirmation';
import OperatorProgramSelection from './UBI/OperatorProgramSelection';

export interface ProgramParticipationProps {
  telematicsProducts: TelematicsProduct[];
  policyEffectiveDate: string;
  transactionEffectiveDate: string;
  onPost: (body: any) => Promise<void>;
  isRTAdmin: boolean;
  bindValidationBypass: boolean;
  setError?: Function;
  version?: string;
  build?: string;
}

//TODO: move to central calling location
const newTelematicsProduct = (): TelematicsProduct => ({
  productCode: '0000',
  productLabel: 'Unknown',
  telematicsOperators: [],
  telematicsVehicles: [],
});

const closeWindow = () => {
  console.info('[CLOSE]');
  window.close();
};

const ProgramParticipation = ({
  telematicsProducts,
  policyEffectiveDate,
  transactionEffectiveDate,
  onPost,
  bindValidationBypass,
  isRTAdmin,
  setError,
  version,
  build,
}: ProgramParticipationProps) => {
  const [operators, setOperators] = useState<TelematicsOperator[]>([]);
  const [product, setProduct] = useState<TelematicsProduct>(newTelematicsProduct());

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const body = {
        telematicsProducts: telematicsProducts
          .filter((x) => x.productCode === RIGHTTRACK_CONTINUOUS_PRICING)
          .map((p) => ({
            productCode: p.productCode,
            telematicsOperators:
              p.telematicsOperators &&
              operators.map((op) => ({
                ...op,
                initialTelematicsStatus: undefined,
              })),
          })),
        bindValidationBypass,
      };

      onPost(body).then(closeWindow);
    },
    [bindValidationBypass, onPost, operators, telematicsProducts],
  );

  const handleCancel = useCallback(() => {
    closeWindow();
  }, []);

  const handleConsent = useCallback((consent: boolean) => {
    if (!consent) {
      closeWindow();
    }
  }, []);

  useEffect(() => {
    setProduct(telematicsProducts.find((x) => x.productCode === RIGHTTRACK_CONTINUOUS_PRICING) ?? newTelematicsProduct());
  }, [telematicsProducts]);

  useEffect(() => {
    if (isEmpty(transactionEffectiveDate) || isEmpty(policyEffectiveDate)) {
      setError && setError('Unable to load effective dates');
    }

    let ted = moment(transactionEffectiveDate);
    let ped = moment(policyEffectiveDate);

    if (ted.isAfter('2099-01-01') || ped.isBefore('1900-01-01')) {
      setError && setError('Unable to load effective dates');
    }
    let daysBetweenDates = ted.diff(ped, 'days');
    let anyOtherOptInNewBusiness = product.telematicsOperators.some(
      (operator) => (operator.priorPolicyTelematicsStatus || '') === RIGHTTRACK_CONTINUOUS_PRICING_DROPDOWN_VALUES.NewBusinessWindow.toString(),
    );

    console.info('isRTAdmin: ', isRTAdmin);

    setOperators(
      product.telematicsOperators.map((operator): TelematicsOperator => {
        return {
          ...operator,
          telematicsStatusCodes: [
            ...getContinuousPricingDropdownCodes(daysBetweenDates, operator.priorPolicyTelematicsStatus ?? '', anyOtherOptInNewBusiness, isRTAdmin, operator.telematicsStatus ?? ''),
          ],
        };
      }),
    );
  }, [product, policyEffectiveDate, transactionEffectiveDate, isRTAdmin, setError]);

  return (
    <Content fullWidth>
      <Content fullWidth className="mb-2">
        {!areAllParticipating(product) &&
            <SalesRepConfirmation onConsent={handleConsent} hasOperatorParticipation={isParticipating(product)} />}

        <GridRow>
          <GridCol base={12} className="p-2">
            <br />
            RightTrack is a safe driving program that lets you save money on your car insurance for safe driving.
            Located right in the Liberty Mutual app, RightTrack observes your driving habits on an ongoing basis and
            provides you with a safe driving score that is applied to your policy at each renewal.
            Soft braking, steady acceleration, and the time of day that you drive all factor in to your score
            and RightTrack provides great tips and coaching along the way.
            Safe drivers will be rewarded with a discount while others could see a rate increase.
            <br />
            <Heading style={{ textDecoration: 'underline' }} type="h4-bold">Program Descriptions</Heading>
            <Heading type="h6-bold">RightTrack Continuous Program</Heading>
            <List>
              <ListItem>
                <strong>Save up to 15% when you sign up.</strong> We’ll review your safe driving habits at renewal where you could earn up
                to 30% in savings.
              </ListItem>
              <ListItem>
                Policy will receive an instant <b>15% enrollment discount</b> on all vehicles <b>as long as all
                operators
                participate.</b> Policies with <b>partial household participation</b> will receive an instant <b>10%
                enrollment discount</b> on all vehicles.
              </ListItem>
              <ListItem>
                <b>Download:</b> We recommend that all drivers on your policy should download our app, complete registration, and
                participate in the program.
              </ListItem>
              <ListItem>
                <b>Drive:</b> The app evaluates things like braking, phone usage, and time of day that are factored into
                  your driving score.
              </ListItem>
              <ListItem>
                <b>Save:</b> Your driving score will be applied to your premium at each renewal. While your <b>rate may
                  increase with high-risk driving</b>, safe drivers save on their premium by participating in this program.
              </ListItem>
              <ListItem>
                <b><u>Requirements:</u></b> Participating operators must have a compatible smartphone with GPS location
                  capabilities:
                  iPhone running minimum iOS 13.0 (iPhone 6s or newer) and Android running minimum OS 7.0 (Nougat).
                  iPads,
                  tablets, and Windows phones are not compatible.
              </ListItem>
            </List>
            <br />
          </GridCol>
        </GridRow>

        <Form onSubmit={handleSubmit} className='mw-100'>
          <GridRow gutters>
            <GridCol base={12}>
              <OperatorProgramSelection
                productCode={RIGHTTRACK_CONTINUOUS_PRICING}
                backgroundCss="teal-background lm-round"
                isRTAdmin={isRTAdmin}
                telematicsOperators={operators}
                onChange={(e) => setOperators(e.target.value)}
              />
            </GridCol>
          </GridRow>

          <GridRow gutters>
            <GridCol base={12} className="d-flex justify-content-end px-4">
              <Button variant="primary" type="submit">
                Apply
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </GridCol>
          </GridRow>
        </Form>

        <GridRow className="teal-background lm-round p-2 ps-3">
          <GridCol base={12}>
            v{version} ({build})
          </GridCol>
        </GridRow>
      </Content>

      <DebugConsole src={{ product, operators }} />
    </Content>
  );
};

export default ProgramParticipation;
