import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const NextSteps = ({ showNextSteps, programName, programNextSteps, onClose }) => {
  const [show, setShow] = useState(true);

  const handleClose = (e) => {
    setShow(false);
    return onClose(e);
  };

  return (
    <Modal show={show && showNextSteps} onHide={handleClose} scrollable size="lg" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{programName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div role="contentinfo">
          {programNextSteps && parse(programNextSteps)}
          {!programNextSteps && 'Next Steps Not Found'}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NextSteps;

NextSteps.defaultProps = {
  showNextSteps: false,
  programName: undefined,
  programNextSteps: undefined,
  onClose: () => {},
};

NextSteps.propTypes = {
  showNextSteps: PropTypes.bool,
  programName: PropTypes.string,
  programNextSteps: PropTypes.string,
  onClose: PropTypes.func,
};
