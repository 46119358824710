import { createRoot } from 'react-dom/client';
import Root from './containers/Root';
import datadog from './utils/datadog';

// global styles
import '@lmig/lmds-react/base.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react18-json-view/src/style.css';
import './style.scss';

datadog();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<Root />);
