import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PremiumSummary from '../components/PremiumSummary';
import AppContainer from '../containers/AppContainer';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/PremiumSummary" element={<PremiumSummary />} />
        <Route path="//:id" element={<AppContainer />} />
        <Route path="//" element={<AppContainer />} />
        <Route path="/:id" element={<AppContainer />} />
        <Route path="/" element={<AppContainer />} />
      </Routes>
    </BrowserRouter>
  );
}

Router.displayName = 'Router';
