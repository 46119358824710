import { isEmpty } from 'lodash';
import { FormEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { inspect } from 'util';
import TelematicsOperator from '../../../model/TelematicsOperator';
import TelematicsProduct from '../../../model/TelematicsProduct';
import TelematicsVehicle from '../../../model/TelematicsVehicle';
import {
  FINAL,
  IN,
  OUT,
  RIGHTTRACK_MOBILE,
  RIGHTTRACK_MOBILE_DROPDOWN_VALUES,
  RIGHTTRACK_NEXTGEN,
  RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES,
  getParticipatingCode,
  getProgramConfig,
  getProgramLabel,
  isCodeImmutable,
  isFinalDiscountOrSurchargeCode,
  isOptInCode,
  isOptOutCode,
  isOptOutKeepFinalDiscount,
} from '../../codes';
import { findProduct, getCodeTypes, isDefault, isParticipating } from '../../product';
import { getEligiblePhoneLabel, getParticipationLabel, getProductDescription, getProductNextSteps } from '../../services/UBIService';
import DebugConsole from '../DebugConsole';
import NextSteps from '../NextSteps';
import ProgramDescription from '../ProgramDescription';
import StyledFormSelect from '../StyledFormSelect';
import OperatorProgramSelection from './OperatorProgramSelection';
import ProgramStatusSelect from './ProgramStatusSelect';
import VehicleProgramSelection from './VehicleProgramSelection';

const closeWindow = () => {
  console.info('[CLOSE]');
  window.close();
};

export interface LegacyProgramParticipationProps {
  telematicsProducts: TelematicsProduct[];
  onPost: (body: any) => Promise<void>;
  isRTAdmin?: boolean;
  bindValidationBypass?: boolean;
  setError?: Function;
  version?: string;
  build?: string;
}

//TODO: move to central calling location
const newTelematicsProduct = (): TelematicsProduct => ({
  productCode: '0000',
  productLabel: 'Unknown',
  telematicsOperators: [],
  telematicsVehicles: [],
});

const LegacyProgramParticipation = ({
  telematicsProducts,
  onPost,
  isRTAdmin = false,
  bindValidationBypass = false,
  setError = (e: any) => {},
  version,
  build,
}: LegacyProgramParticipationProps) => {
  // CFA values
  const [product, setProduct] = useState<TelematicsProduct>(newTelematicsProduct());
  const productsAvailable = useMemo(() => telematicsProducts.map((item) => item.productCode), [telematicsProducts]);
  const [operators, setOperators] = useState<TelematicsOperator[]>([]);
  const [vehicles, setVehicles] = useState<TelematicsVehicle[]>([]);

  const [productLabel, setProductLabel] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productNextSteps, setProductNextSteps] = useState('');

  const [productSelected, setProductSelected] = useState('');
  const [productEnrolled, setProductEnrolled] = useState(false);
  const [showNextSteps, setShowNextSteps] = useState(false);
  const [validated, setValidated] = useState(false);

  // RTM form values
  const [consentDropdown, setConsentDropdown] = useState(false);
  const [participationLabel, setParticipationLabel] = useState('');
  const [isAnyOperatorDrivingPeriodComplete, setIsAnyOperatorDrivingPeriodComplete] = useState(false);
  const [areAllNextGenOperatorsPreviouslyNotParticipating, setAreAllNextGenOperatorsPreviouslyNotParticipating] = useState(false);
  const [areAllOperatorsPreviouslyNotParticipating, setAreAllOperatorsPreviouslyNotParticipating] = useState(false);
  const [areAllMobileOperatorsOptOutKeep10, setAreAllMobileOperatorsOptOutKeep10] = useState(false);
  const [eligiblePhoneLabel, setEligiblePhoneLabel] = useState('');
  const [optingOut, setOptingOut] = useState(false);
  const [programStatus, setProgramStatus] = useState();
  const [isUserChange, setIsUserChange] = useState(false);

  const backgroundCss = useMemo(() => (product.productCode === RIGHTTRACK_NEXTGEN ? 'yellow-background' : 'blue-background'), [product.productCode]);

  useEffect(() => {
    setAreAllNextGenOperatorsPreviouslyNotParticipating(
      product.productCode === RIGHTTRACK_NEXTGEN &&
        operators.every((operator) => !operator.priorPolicyTelematicsStatus || operator.priorPolicyTelematicsStatus === '99'),
    );
    setAreAllOperatorsPreviouslyNotParticipating(
      (product.productCode === RIGHTTRACK_NEXTGEN || product.productCode === RIGHTTRACK_MOBILE) &&
        operators.every((operator) => !operator.priorPolicyTelematicsStatus || operator.priorPolicyTelematicsStatus === '99'),
    );
    setAreAllMobileOperatorsOptOutKeep10(
      product.productCode === RIGHTTRACK_MOBILE &&
        operators.every((operator) => !operator.telematicsStatus || operator.telematicsStatus === '29' || operator.telematicsStatus === '99'),
    );
  }, [product, operators]);

  // determine which products participating/or defaults
  useEffect(() => {
    if (telematicsProducts.length > 0) {
      // determine which product (if any) is currently participating
      const participatingProducts = telematicsProducts.filter((item) => isParticipating(item));

      if (participatingProducts.length > 0) {
        setProductSelected(participatingProducts[0].productCode ?? '');
        setProductEnrolled(true);
      } else {
        console.log({ telematicsProducts });
        // determine which product (if any) is the default
        const defaultProducts = telematicsProducts.filter((item) => isDefault(item));
        console.log({ defaultProducts });

        setProductSelected((defaultProducts.length > 0 ? defaultProducts[0].productCode : telematicsProducts[0].productCode) ?? '');
        setProductEnrolled(false);
      }
    }
  }, [telematicsProducts]);

  // set operators(with the telematic status codes
  useEffect(() => {
    setProductLabel(product.productLabel || getProgramLabel(product.productCode));

    if (product.productCode === RIGHTTRACK_MOBILE) {
      setConsentDropdown(productEnrolled);
    }

    if (product.productCode === RIGHTTRACK_NEXTGEN) {
      setConsentDropdown(productEnrolled);

      setOperators((oprs) =>
        oprs.map((operator) => {
          if (!isRTAdmin && !isAnyOperatorDrivingPeriodComplete && (operator.renewalTelematicsStatus || operator.telematicsProductEarnDate)) {
            setIsAnyOperatorDrivingPeriodComplete(true);
          }

          if (
            !areAllNextGenOperatorsPreviouslyNotParticipating &&
            (isOptInCode(product.productCode, operator.telematicsStatus) ||
              isFinalDiscountOrSurchargeCode(product.productCode, operator.telematicsStatus))
          ) {
            if (isRTAdmin) {
              return {
                ...operator,
                telematicsStatusCodes: [
                  ...RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.finalDiscountCodeList,
                  ...RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.finalSurchargeCodeList,
                ],
              };
            }
          }
          return operator;
        }),
      );

      if (isRTAdmin) {
        setProduct((p) => ({
          ...p,
          telematicsStatusCodes: [
            ...RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.keepInitialDiscountCodeList,
            ...RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.optOutCodeList,
          ].map((c) => `${c}`),
        }));
      } else {
        setProduct((p) => ({
          ...p,
          telematicsStatusCodes: RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.optOutCodeList.map((c) => `${c}`),
        }));
      }
    }
  }, [
    areAllNextGenOperatorsPreviouslyNotParticipating,
    isAnyOperatorDrivingPeriodComplete,
    isRTAdmin,
    product.productCode,
    product.productLabel,
    productEnrolled,
  ]);

  // if RTM, set dropdown values
  useEffect(() => {
    if (product.productCode === RIGHTTRACK_MOBILE) {
      if (areAllMobileOperatorsOptOutKeep10 && !productEnrolled) {
        setOperators((oprs) =>
          oprs.map((operator) => {
            if (isOptOutKeepFinalDiscount(product.productCode, operator.telematicsStatus)) {
              return {
                ...operator,
                telematicsStatusCodes: [...RIGHTTRACK_MOBILE_DROPDOWN_VALUES.optOutCodeList],
              };
            }
            return operator;
          }),
        );
      }
    }
  }, [areAllMobileOperatorsOptOutKeep10, product.productCode, productEnrolled]);

  // have product -> get product label and program label
  useEffect(() => {
    if (product) {
      setProductLabel(product.productLabel || getProgramLabel(product.productCode));
    }
  }, [product]);

  // have product -> get description
  useEffect(() => {
    if (product && !productDescription && getProgramConfig(product.productCode).Description) {
      getProductDescription(product)
        .then(setProductDescription)
        .catch((ex) => {
          ex.message && console.error(ex.message);
          setError(ex);
          setProductDescription('');
        });
    }
  }, [product, productDescription, setError]);

  // have product -> get participation Label
  useEffect(() => {
    if (product && !participationLabel && getProgramConfig(product.productCode).ParticipationLabel) {
      getParticipationLabel(product)
        .then(setParticipationLabel)
        .catch((ex) => {
          ex.message && console.error(ex.message);
          setError(ex);
          setParticipationLabel('');
        });
    }
  }, [participationLabel, product, setError]);

  // have product -> get eligible Phone Label
  useEffect(() => {
    if (product && !eligiblePhoneLabel && getProgramConfig(product.productCode).EligiblePhoneLabel) {
      getEligiblePhoneLabel(product)
        .then(setEligiblePhoneLabel)
        .catch((ex) => {
          ex.message && console.error(ex.message);
          setError(ex.message);
          setEligiblePhoneLabel('');
        });
    }
  }, [eligiblePhoneLabel, product, setError]);

  // have product  -> get product Next Steps
  useEffect(() => {
    if (product && !productNextSteps && getProgramConfig(product.productCode).NextSteps) {
      getProductNextSteps(product)
        .then(setProductNextSteps)
        .catch((ex) => {
          ex.message && console.error(ex.message);
          setError(ex);
          setProductNextSteps('');
        });
    }
  }, [product, productNextSteps, setError]);

  // check product selection changed/process it
  useEffect(() => {
    console.info('useEffect', { productSelected });

    if (!isEmpty(productSelected)) {
      console.info('product selection has changed', { productSelected });
      setProductDescription('');
      setProductNextSteps('');
      setProduct(findProduct(telematicsProducts, productSelected));
      setOperators(() => {
        return telematicsProducts
          .map((item) => (item.telematicsOperators || []).map((op) => ({ ...op, productCode: item.productCode ?? '' })))
          .flat();
      });
      setVehicles(() => {
        return telematicsProducts.map((item) => (item.telematicsVehicles || []).map((vh) => ({ ...vh, productCode: item.productCode ?? '' }))).flat();
      });
    }
  }, [productSelected, telematicsProducts]);

  const updateOperator = useCallback(
    (operator: TelematicsOperator): TelematicsOperator => {
      const participatingCode = getParticipatingCode(product.productCode, consentDropdown);

      // If the telematics status code is one of the "Opt-Out"s, then clear out telematicsStatusCodes (dropdown values)
      let telematicsStatusCodes = isOptOutCode(product.productCode, participatingCode) || consentDropdown ? [] : operator.telematicsStatusCodes || [];
      if (isOptInCode(product.productCode, participatingCode) && isRTAdmin && product.productCode === RIGHTTRACK_NEXTGEN) {
        telematicsStatusCodes = [
          participatingCode,
          ...RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.finalDiscountCodeList,
          ...RIGHTTRACK_NEXTGEN_DROPDOWN_VALUES.finalSurchargeCodeList,
        ];
      }

      const codeImmutable = isCodeImmutable(product.productCode, operator.telematicsStatus);

      let newTelematicsStatus = '';

      if (codeImmutable) {
        newTelematicsStatus = operator.telematicsStatus;
      } else if (consentDropdown && operator.priorPolicyTelematicsStatus) {
        // if the ubi screen launched without being enrolled a product, set status to participatingCode
        // otherwise, if they were enrolled or had an opt-out code, the old values should persist
        newTelematicsStatus =
          isOptOutCode(product.productCode, operator.priorPolicyTelematicsStatus) && !productEnrolled
            ? participatingCode
            : operator.priorPolicyTelematicsStatus;
      } else if (!consentDropdown || (consentDropdown && !operator.priorPolicyTelematicsStatus)) {
        newTelematicsStatus = participatingCode;
      }

      console.info('updateOperator', { participatingCode, telematicsStatusCodes });

      return {
        ...operator,
        initialTelematicsStatus: operator.initialTelematicsStatus,
        telematicsStatus: newTelematicsStatus,
        telematicsStatusCodes,
      };
    },
    [consentDropdown, isRTAdmin, product.productCode, productEnrolled],
  );

  // check if consentDropdown changed and update operator
  useEffect(() => {
    console.info('useEffect', { consentDropdown });

    if (isUserChange) {
      if (consentDropdown) {
        setOptingOut(false);
        setProgramStatus(undefined);

        setOperators((oprs) => oprs.map(updateOperator));
      }

      if (!optingOut && !consentDropdown) {
        setOptingOut(true);
        setProgramStatus(undefined);

        setOperators((oprs) => oprs.map(updateOperator));
      }

      setIsUserChange(false);
    }
  }, [isUserChange, optingOut, consentDropdown, updateOperator]);

  // when programStatus, set operators
  useEffect(() => {
    console.info('useEffect', { programStatus });

    if (programStatus) {
      setOperators((oprs) => oprs.map((opr) => ({ ...opr, telematicsStatus: programStatus })));
    }
  }, [programStatus]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.currentTarget.checkValidity()) {
      const body = {
        telematicsProducts: telematicsProducts.map((p) => ({
          productCode: p.productCode,
          telematicsOperators:
            p.telematicsOperators &&
            operators
              .filter((op) => op.productCode === p.productCode)
              .map((op) => ({
                ...op,
                initialTelematicsStatus: undefined,
              })),
          telematicsVehicles: p.telematicsVehicles && vehicles.filter((vh) => vh.productCode === p.productCode).map((vh) => vh),
        })),
        bindValidationBypass,
      };

      onPost(body).then(() => {
        const types = [
          ...getCodeTypes(product.productCode, !isEmpty(product.telematicsOperators) ? operators : []),
          ...getCodeTypes(product.productCode, !isEmpty(product.telematicsVehicles) ? vehicles : []),
        ];

        const OUTs = types.filter((type) => type === OUT || type === FINAL);
        const INs = types.filter((type) => type === IN);

        console.info(inspect({ types, OUTs, INs }, { depth: null }));
        console.info('out count', OUTs.length);
        console.info('in count', INs.length);
        console.info('show next steps', !(OUTs.length > 0 || INs.length === 0));

        const programConfig = getProgramConfig(product.productCode);

        console.info({ programConfig });

        if (programConfig && programConfig.OperatorProduct) {
          if (OUTs.length > 0 || INs.length === 0) {
            closeWindow();
          } else {
            setShowNextSteps(true);
          }
        }

        if (programConfig && programConfig.VehicleProduct) {
          if (INs.length === 0 || !programConfig.NextSteps) {
            closeWindow();
          } else {
            setShowNextSteps(true);
          }
        }
      });
    } else {
      setValidated(true);
    }
  };

  const handleCancel: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    closeWindow();
  };

  return (
    <Container fluid className="m-0 p-0">
      <Container fluid className="mb-2">
        <NextSteps
          showNextSteps={showNextSteps}
          programNextSteps={productNextSteps}
          programName={productLabel}
          onClose={() => {
            closeWindow();
          }}
        />

        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className={`dialog-titlebar ${backgroundCss}`}>
            <Col sm>Usage Based Insurance Eligibility and Enrollment</Col>
          </Row>

          <Row>
            <Col sm>
              <p>Usage based insurance allows insurers to adjust premiums based on real driving data provided by the customer via technology</p>
              <h5>
                <b>{productLabel}</b>
              </h5>
            </Col>
          </Row>

          <div className={`${getProgramConfig(productSelected).RedBorder && 'border border-danger border-5 p-3 my-2'}`}>
            {getProgramConfig(productSelected).RedBorder && (
              <>
                <h2 className="text-decoration-underline">MANDATORY TALK TRACK CONSENT</h2>
                <p className="fw-bold">
                  <span className="text-danger">*</span> Please read the following to the customer:
                </p>
              </>
            )}

            {productSelected && getProgramConfig(productSelected).Description ? (
              <Row className={`${getProgramConfig(productSelected)}`}>
                <Col sm>
                  <ProgramDescription programInfo={productDescription} />
                </Col>
              </Row>
            ) : (
              <p />
            )}

            <Row>
              <Col sm>
                <h3>
                  <u>Opt-in Status</u>
                </h3>
              </Col>
            </Row>

            {productSelected && !getProgramConfig(productSelected).LegacyProduct && (
              <Form.Group as={Row} controlId="programSelection">
                <Col>
                  <Form.Label>Program Selection</Form.Label>
                </Col>
                <Col>
                  <Form.Control as="select" onChange={(e) => setProductSelected(e.target.value)} value={productSelected} disabled={productEnrolled}>
                    {productsAvailable.map((code) => (
                      <option key={code} value={code}>
                        {getProgramLabel(code)}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            )}

            {productSelected && getProgramConfig(productSelected).OperatorProduct && (
              <>
                <Form.Group as={Row} controlId="consentDropdown">
                  <Col>
                    <Form.Label>
                      <span className="text-danger">*</span> Does the customer agree to continue?
                    </Form.Label>
                  </Col>
                  <Col>
                    <StyledFormSelect
                      isChecked={consentDropdown}
                      disable={isAnyOperatorDrivingPeriodComplete || (optingOut && !areAllOperatorsPreviouslyNotParticipating)}
                      label={participationLabel}
                      myChange={(e) => {
                        setConsentDropdown(e.target.value === 'true');
                        setIsUserChange(true);
                      }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
          </div>

          {/* only show this field if the program level status code array is not empty and participation is unchecked */}
          {productSelected && getProgramConfig(productSelected).OperatorProduct && (
            <>
              <>
                {optingOut &&
                  product &&
                  product.telematicsStatusCodes &&
                  !areAllNextGenOperatorsPreviouslyNotParticipating &&
                  product.telematicsStatusCodes.length > 0 && (
                    <>
                      <Form.Group as={Row} controlId="programStatus" className="mt-2">
                        <Col>
                          <Form.Label>Program Status</Form.Label>
                        </Col>
                        {productSelected && (
                          <Col>
                            <ProgramStatusSelect
                              productCode={productSelected}
                              programStatusCodes={product.telematicsStatusCodes}
                              isRTAdmin={isRTAdmin}
                              value={programStatus}
                              onChange={(e: any) => setProgramStatus(e.target.value)}
                              addBlank
                              required
                              pattern="*"
                              errorText='Please Select an "Opt Out Reason"'
                            />
                          </Col>
                        )}
                      </Form.Group>
                    </>
                  )}
              </>
              <OperatorProgramSelection
                productCode={productSelected}
                backgroundCss={backgroundCss}
                telematicsOperators={operators}
                onChange={(e) => setOperators(e.target.value)}
              />
            </>
          )}

          {productSelected && getProgramConfig(productSelected).VehicleProduct && (
            <VehicleProgramSelection
              products={telematicsProducts}
              telematicsVehicles={vehicles}
              onChange={(e) => setVehicles(e.target.value)}
              legacyProduct={getProgramConfig(productSelected).LegacyProduct}
            />
          )}

          <Row className="mt-2">
            <Col>
              <ButtonToolbar className="justify-content-end">
                <Button className={`${backgroundCss} me-2`} type="submit">
                  Apply
                </Button>
                <Button className={backgroundCss} onClick={handleCancel}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Form>

        <Row className={`${backgroundCss} mt-2 p-2`}>
          <Col>
            v{version} ({build})
          </Col>
        </Row>
      </Container>

      <DebugConsole
        src={{
          isRTAdmin,
          productSelected,
          programStatus,
          telematicsProducts,
          product,
          operators,
          vehicles,
        }}
      />
    </Container>
  );
};

export default LegacyProgramParticipation;
