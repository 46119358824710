import { Toggle } from '@lmig/lmds-react';
import { useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import JsonView from 'react18-json-view';

export interface DebugConcoleProps {
  src: any;
}

const DebugConsole = ({ src }: DebugConcoleProps) => {
  const [debug, setDebug] = useState(false);

  return (
    <Card className="m-0 p-0">
      <Card.Header>
        <Toggle labelVisual="Debug Console" onChange={() => setDebug(!debug)} checked={debug} />
      </Card.Header>
      {debug && (
        <Card.Body>
          <Alert variant="info">
            <JsonView collapsed src={src} />
          </Alert>
        </Card.Body>
      )}
    </Card>
  );
};

export default DebugConsole;
