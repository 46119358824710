import axios from 'axios';
import Health from '../../model/Health';
import TelematicsProduct from '../../model/TelematicsProduct';

export const getHealth = async () => {
  const { data } = await axios.get<Health>(`/health`);
  return data;
};

export const getProductDescription = async (product: TelematicsProduct) => {
  const { data } = await axios.get<string>(product.productDescription || `/api/product/${product.productCode}/description/v1`);
  return data;
};

export const getParticipationLabel = async (product: TelematicsProduct) => {
  const { data } = await axios.get<string>(product.participationLabel || `/api/product/${product.productCode}/participationLabel/v1`);
  return data;
};

export const getEligiblePhoneLabel = async (product: TelematicsProduct) => {
  const { data } = await axios.get<string>(product.eligiblePhoneLabel || `/api/product/${product.productCode}/eligiblePhoneLabel/v1`);
  return data;
};

export const getProductNextSteps = async (product: TelematicsProduct) => {
  const { data } = await axios.get<string>(product.productNextSteps || `/api/product/${product.productCode}/nextsteps/v1`);
  return data;
};

const UBIService = {
  getHealth,
  getProductDescription,
  getParticipationLabel,
  getEligiblePhoneLabel,
  getProductNextSteps,
};

export default UBIService;
