class CfaUtils {
  static getFetchUbiDataUrl(url: string, transactionId: string | number) {
    return CfaUtils.buildUrl(url, transactionId, '/CFA/getUbiData.do');
  }

  static getUpdateUbiDataUrl(url: string, transactionId: string | number) {
    return CfaUtils.buildUrl(url, transactionId, '/CFA/updateUbiData.do');
  }

  static buildUrl(url: string, id: string | number, action: string) {
    let newUrl;

    if (url && !/^$/.test(url)) {
      // Not blank
      const parsedUrl = new URL(url);
      let { protocol } = new URL(url);

      // convert http to https
      if (!/[\w.]+:\d+/.test(parsedUrl.host)) {
        protocol = 'https:';
      }

      newUrl = `${protocol}//${parsedUrl.host}${action}${parsedUrl.search}`;
    } else {
      // Url is blank

      let search = `?id=${id || ''}`;
      newUrl = `${window.location.protocol}//${window.location.host}${action}${search}`;
    }

    return newUrl;
  }

  static isLocal(): boolean {
    const { hostname } = window.location;
    return /localhost/.test(hostname);
  }
}

export default CfaUtils;
