import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { getStatusLabel, isFinalDiscountOrSurchargeCode } from '../../codes';
import ProgramStatusSelect from './ProgramStatusSelect';
import { Container } from 'react-bootstrap';

function OperatorProgramSelection({ productCode, backgroundCss, telematicsOperators, isRTAdmin, onChange }) {
  const handleChange = (customerId, status) => {
    onChange({
      target: {
        value: telematicsOperators.map((operator) =>
          operator.customerId === customerId
            ? {
                ...operator,
                telematicsStatus: status,
                initialTelematicsStatus: undefined,
                renewalTelematicsStatus: isFinalDiscountOrSurchargeCode(productCode, status) ? status : '',
              }
            : operator,
        ),
      },
    });
  };

  return (
    <Container fluid>
      <Row className={`form-group ${backgroundCss} p-2`}>
        <Col>Operator</Col>
        <Col>Status</Col>
      </Row>

      {telematicsOperators.map(({ firstName, lastName, customerId, telematicsStatus, telematicsStatusCodes, renewalTelematicsStatus }) => (
        <Form.Group as={Row} className="mt-2" controlId={`operator-${customerId}`} key={customerId}>
          <Col className="d-flex">
            <Form.Label className="align-self-center">
              <div>
                {firstName}
                &nbsp;
                {lastName}
              </div>
              {renewalTelematicsStatus && (
                <small>
                  Renewal status: &nbsp;
                  {getStatusLabel(productCode, renewalTelematicsStatus, isRTAdmin)}
                </small>
              )}
            </Form.Label>
          </Col>
          <Col>
            <ProgramStatusSelect
              productCode={productCode}
              programStatusCodes={telematicsStatusCodes || []}
              value={telematicsStatus}
              isRTAdmin={isRTAdmin}
              onChange={(e) => handleChange(customerId, e.target.value)}
              addBlank={!telematicsStatus || telematicsStatus.length === 0}
            />
          </Col>
        </Form.Group>
      ))}
    </Container>
  );
}

export default OperatorProgramSelection;

OperatorProgramSelection.defaultProps = {
  telematicsOperators: [],
  onChange: () => {},
};

OperatorProgramSelection.propTypes = {
  productCode: PropTypes.string.isRequired,
  backgroundCss: PropTypes.string.isRequired,
  telematicsOperators: PropTypes.arrayOf(
    PropTypes.shape({
      customerId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      telematicsStatus: PropTypes.string,
      telematicsStatusCodes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    }),
  ),
  isRTAdmin: PropTypes.bool,
  onChange: PropTypes.func,
};
